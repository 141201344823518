<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full lg:px-20 space-y-7">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Danh sách bản nhạc ({{ sheets.paginatorInfo.total }})</h1>
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4" v-if="sheets.data.length">
            <SheetItem v-for="sheet in sheets.data" :sheet="sheet" :key="'sheet' + sheet.id"/>
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="sheets.paginatorInfo.lastPage > 1"
                v-model="sheets.paginatorInfo.currentPage"
                :pages="sheets.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadSheets"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SheetItem from "./SheetItem";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: 'Music',
  components: {
    VPagination,
    SheetItem
  },
  data() {
    return {
      sheets: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadSheets() {
      let query = `query($page: Int) {
        sheets(first: 20, page: $page, orderBy: [{column: "title", order: ASC}], where: {AND: [{column: "content", operator: NEQ, value: ""}]}) {
          data {
            id
            slug
            title
            year
            composers(first: 20) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.sheets.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.sheets) {
              this.sheets = data.data.sheets;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadSheets();
    changePageTitle("Bản nhạc");
  }
}
</script>
