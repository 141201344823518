<template>
  <div class="flex items-center space-x-4 py-3 hover:bg-gray-100 rounded-md -mx-2 px-2">
    <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
      <router-link :title="sheet.title" class="text-lg font-semibold" :to="{name: 'SheetDetail', params: {id: sheet.id, slug: sheet.slug}}">
        <img :alt="sheet.title" :src="sheet.composers && sheet.composers.data && sheet.composers.data[0] && sheet.composers.data[0].avatar ? sheet.composers.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </router-link>
    </div>
    <div class="flex-1">
      <router-link :title="sheet.title" class="text-lg font-semibold" :to="{name: 'SheetDetail', params: {id: sheet.id, slug: sheet.slug}}">
        {{ sheet.title }}
      </router-link>
      <div class="text-sm text-gray-500 mt-0.5 people-in-sheet" v-if="sheet.composers && sheet.composers.data">
        <PeopleOfSong :people="sheet.composers.data" type="composer"/>
      </div>
      <div v-if="sheet.year" class="text-sm text-gray-500 mt-1" :title="'Sáng tác năm ' + sheet.year">
        {{ sheet.year }}
      </div>
    </div>
  </div>
</template>

<script>
import PeopleOfSong from "../song/PeopleOfSong";

export default {
  name: "SheetItem",
  props: {
    sheet: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  components: {
    PeopleOfSong
  }
}
</script>
